


































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import BaseSelect from '@/components/forms/BaseSelect/BaseSelect.vue'
import BaseTextarea from '@/components/forms/BaseTextarea.vue'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'
import { Model } from '@/modules/MyRoomsPage/components/AddressForm.vue'
import axios from 'axios'

interface City {
  id: number
  name: string
}

interface Metro {
  city: number
  color: string
  id: number
  name: string
}

const validations = {
  name: { required, maxLength: maxLength(250) },
  city: { required },
  address: { required },
  tel: { required, minLength: minLength(16) },
  wayDirections: { maxLength: maxLength(3000) },
  parkingInfo: { maxLength: maxLength(3000) },
}

@Component({
  name: 'AddAddressForm',
  components: {
    BaseSelect,
    FormGroup,
    Field,
    BaseButton,
    BaseTextarea,
    BaseCheckbox,
  },
  validations,
})
export default class AddAddressForm extends Vue {
  @Prop({ required: true })
  value!: Model

  @Watch('value')
  onValueChanged(val: Model): void {
    this.updateModel(val)
  }

  @Watch('city')
  onCityChanged(val: Model): void {
    this.metroStations = null
  }

  name = ''
  city: null | number = null
  address = ''
  tel = ''
  parkingInfo = ''
  wayDirections = ''
  metroStations: null | number = null
  active = false

  cityList: City[] = []
  allMetroList: Metro[] = []

  public updateModel(data: Model): void {
    this.name = data?.name ?? ''
    this.city = data?.city ?? null
    this.address = data?.address ?? ''
    this.tel = data?.tel ?? ''
    this.wayDirections = data?.wayDirections ?? ''
    this.metroStations = data?.metroStations ?? null
    this.active = data?.active ?? false
  }

  public update(): void {
    this.$emit('input', {
      name: this.name,
      city: this.city,
      address: this.address,
      tel: this.tel,
      wayDirections: this.wayDirections,
      metroStations: this.metroStations,
      active: this.active,
    })
  }

  get nameErrorText(): string | null {
    const field = this.getValidationField('name')
    const isDirty = field.$dirty

    if (isDirty && !field.required) {
      return 'Это обязательное поле'
    } else if (isDirty && !field.maxLength) {
      return 'Максимальная длина - 250 символов'
    }

    return null
  }

  get metroList(): Metro[] {
    return this.allMetroList.filter((metro) => metro.city === this.city) ?? []
  }

  get telErrorText(): string | null {
    const field = this.getValidationField('tel')
    const isDirty = field.$dirty

    if (isDirty && !field.required) {
      return 'Это обязательное поле'
    } else if (isDirty && !field.minLength) {
      return 'Телефон должен быть заполнен полностью'
    }

    return null
  }

  public async fetchCityList(): Promise<any> {
    return await axios.get('/api/b2b/v1/cities')
  }

  public async fetchMetroList(): Promise<any> {
    return await axios.get('/api/b2b/v1/metro-stations')
  }

  public getValidationField(key: string): any {
    return this.$v[key]
  }

  public checkValidity(): boolean {
    this.$v.$touch()

    return !this.$v.$anyError
  }

  public created(): void {
    this.fetchCityList().then(({ data }) => {
      this.cityList = data.data as City[]
    })

    this.fetchMetroList().then(({ data }) => {
      this.allMetroList = data.data as Metro[]
    })
  }
}
